$orange: #ed5e3a;
$orangeDarked: #d7350c;
$red: #cc010f;
$redDarked: #6c0007;
$redUltraDarked: #3c0007;
$black: #151515;
$lightBlack: #1b1919;
$lightTransparentBlack: #1b191923;
$white: #f6f6f6;
$grayHarder: rgba(27, 25, 25, 0.137254902);
$gray: #eaeaea;
$hardGray: #524B4B;
$lightGray: #f6f6f6;
$transparent: rgba(246, 246, 246, 0);
$transparentLight: rgba(31, 27, 26, 0.1);
$transparentGray: rgba(246, 246, 246, 0.5);
$transparentWhite: rgba(246, 246, 246, 0.3);
$transparentWhiteLight: rgba(246, 246, 246, 0.25);
$transparentBlack: rgba(27, 25, 25, 0.5);
$transparentBlackLight: rgba(27, 25, 25, 0.8);
$inputDisabled: #8d8d8d;
$green: #00b15b;
$radialGradientBottomLeft: radial-gradient(ellipse at bottom left,
    $black 50%,
    $redDarked 100%);
$radialGradientBottomRight: radial-gradient(ellipse at bottom right,
    $black 50%,
    $redDarked 100%);



@mixin flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-justify-center {
  display: flex;
  justify-content: center;
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-space-between-align-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-content-end-align-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}