@use "@angular/material" as mat;
@import "../src/app/variables";
@include mat.core();

$front-theme: mat.define-theme((color: (theme-type: light,
        primary: mat.$azure-palette,
        tertiary: mat.$blue-palette,
      ),
      density: (scale: 0,
      ),
    ));

:root {
  @include mat.all-component-themes($front-theme);
}

@font-face {
  font-family: Macan-Regular;
  src: url(assets/fonts/Macan-Regular.otf);
}

@font-face {
  font-family: Macan-Bold;
  src: url(assets/fonts/Macan-Black.otf);
}

@font-face {
  font-family: Macan-Regular-Italic;
  src: url(assets/fonts/Macan-Regular-Italic.otf);
}

@font-face {
  font-family: Macan-Semibold;
  src: url(assets/fonts/Macan-Semibold.otf);
}

@font-face {
  font-family: Macan-Light;
  src: url(assets/fonts/Macan-Light.otf);
}

@font-face {
  font-family: MonumentExtended-Regular;
  src: url(assets/fonts/MonumentExtended-Regular.otf);
}

@font-face {
  font-family: MonumentExtended-Ultrabold;
  src: url(assets/fonts/MonumentExtended-Ultrabold.otf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: $black;
  color: $white;
  font-family: "Macan-Regular", sans-serif;
}

html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
}

input {
  font-size: 1.6rem;

  &::placeholder {
    font-size: 1.6rem;
  }
}

.small {
  font-size: 1.3rem;
  line-height: 16px;
}

.more-small {
  font-size: 1.1rem;
}

.unstyle-text {
  color: $white;
  text-decoration: none;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $white;
}

.container-component {
  padding: 0 2rem;
  padding-top: 3rem;
  padding-bottom: 12rem;
  min-height: 100vh;
}

/* Cibler les appareils iOS en mode plein écran */
@supports (-webkit-touch-callout: none) {
  .container-component {
    padding-top: 6rem;
  }
}

.disabled {
  opacity: 0.5;
}

.more-rounded {
  border-radius: 1rem;
}

h1 {
  font-size: 3rem;
  font-family: "MonumentExtended-Ultrabold", sans-serif;
}

h2 {
  font-size: 2.5rem;
  line-height: 3.5rem;
}

h3 {
  font-size: 1.8rem;
  line-height: 3rem;
}

h4 {
  font-size: 1.5rem;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "Macan-Bold", sans-serif;
  margin-bottom: 0;
}

.hideForBeta {
  display: none;
}

p,
a,
b,
textarea,
li {
  font-family: "Macan-Regular", sans-serif;
  font-size: 1.6rem;
  line-height: 1.7rem;
  margin: 0;
}

label {
  font-size: 1.6rem;
}

textarea {
  font-size: 1.6rem;

  &::placeholder {
    font-size: 1.6rem;
  }
}

select {
  font-size: 1.6rem;

  option {
    font-size: 1.6rem;
  }
}

button {
  font-family: "Macan-Regular", sans-serif;
  font-size: 1.5rem;
  cursor: pointer;
  color: $black;
}

.regular-italic {
  font-family: "Macan-Regular-Italic", sans-serif;
}

.danger {
  color: $red;
}

.semi-bold {
  font-family: "Macan-Semibold", sans-serif;
}

.bold {
  font-family: "Macan-Bold", sans-serif;
}

.light {
  font-family: "Macan-Light", sans-serif;
}

.vertical-scroll-container {
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.horizontal-scroll-container {
  overflow-x: auto;
  padding-bottom: 0.5rem;

  &::-webkit-scrollbar {
    margin-top: 1rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $red;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}

.leaflet-container {
  background: $black !important;
}

.custom-marker>div {
  background-color: $red;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.marker-cluster>div {
  @include flex-center-center;
  background-color: $red;
  border: 1px solid $white;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.marker-cluster-small div {
  background-color: $red !important;
}

.marker-cluster-small {
  background-color: rgba(204, 1, 15, 0.6) !important;
}

.leaflet-interactive {
  stroke: $white;
}

.size-up {
  font-size: 2rem;
}

.leaflet-popup-content-wrapper {
  padding: 0 !important;

  .leaflet-popup-content {
    margin: 0 !important;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border-top: 0.5rem solid $redDarked;
    background-color: $white;

    h3 {
      color: $redDarked;
    }

    img {
      width: 10rem !important;
      height: 10rem !important;
      object-fit: cover;
      border-radius: 0.5rem;
    }
  }
}

// Label soon

.blur-content {
  filter: blur(0.2rem);
}

.relative {
  position: relative;
}

.absolute-center {
  position: absolute;
  top: 0;
  right: 1.5rem;
  transform: rotate(20deg);
}

app-label-soon {
  z-index: 10;
}

// Bottom sheet
mat-bottom-sheet-container {
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 2.5rem !important;
  max-height: 90vh !important;
}